<script lang="tsx">
import {VTextField} from 'vuetify/lib/components/VTextField/VTextField.mjs';
import {TextInputProps} from "~/components/LComponents/Props/TextInputProps";
import {StateProps} from "~/components/LComponents/Props/StateProps";
import LField from "~/components/LComponents/LField.vue";
import {IconsProps} from "~/components/LComponents/Props/IconsProps";
import {TextProps} from "~/components/LComponents/Props/TextProps";
import {BaseInputProps} from "~/components/LComponents/Props/BaseInputProps.client";
import {ThemeProps} from "~/components/LComponents/Props/ThemeProps";
import {HintProps} from "~/components/LComponents/Props/HintProps";
import useValidation from "~/service/useValidation";
import {mdiAlertCircleOutline, mdiEyeOffOutline, mdiEyeOutline} from "@mdi/js";
import directive from "maska/src/directive";
import {NumberInputProps} from "~/components/LComponents/Props/NumberInputProps";
import {TestIdProps} from '~/components/LComponents/Props/TestIdProps';
import mask from "maska/src/mask";

export default defineComponent({
  components: {VTextField,},
  directives: {
    maska: directive,
  },
  props: {
    modelValue: {
      type: [String, Number, null,],
      required: true,
    },
    ...BaseInputProps,
    ...TextInputProps,
    ...NumberInputProps,
    ...HintProps,
    ...IconsProps,
    ...TextProps,
    ...StateProps,
    ...ThemeProps,
    ...TestIdProps,
  },
  emits: ['update:modelValue', 'blur', 'focus', 'input',],
  setup(props, context) {
    const model = computed<string | number | null>({
      get: () => {
        const sign = props.modelValue < 0 ? '-' : '';
        return (props.mask && sign + mask(String(props.modelValue), props.mask,)) || props.modelValue;
      },
      set: (value) => {
        if (!props.mask) {
          emitModelValue(value);
        } else {
          emitModelValue(mask(String(value), props.mask, undefined, false));
        }
      },
    });

    /*  onMaska returns event with raw, not masksed value if v-mask (event.target.dataset.maskRawValue) */
    const setUnmaskedModelFromEvent = (event): void => {
      const value = event.target.dataset.maskRawValue;
      //onMaska emits this function during setup, its a hack for not validating input on load
      if (value) {
        emitModelValue(value);
      }
    };

    const emitModelValue = (value: string | number): void => {
      context.emit('update:modelValue', value !== "" ? formatValueByType(value) : value);
    };

    const formatValueByType = (value) => {
      return isNumberInput.value ? Number(value) : value;
    };

    const isNumberInput = computed<boolean>(() => {
      return props.type === 'number';
    });

    const inputWidth = computed((): string => {
      let length = (model.value && `${model.value}`.length) || 1;

      //poprawa złego ustawiania szerokosci inputa - spacje są węższe niż cyfry dlatego źle była wyliczana potrzebna szerokość dla wiekszych liczb
      if (length > 1) {
        const spacesCount =  (`${model.value}`.split(' ').length - 1) || 0;
        length -= spacesCount / 2;
      }

      return `${length}ch`;
    });
    const {isPasswordValid, isEmailValid, isPhoneValid,} = useValidation();
    const showPassword = ref<boolean>(false);
    const isFocused = ref<boolean>(false);

    const setFocus = (newIsFocus: boolean) => {
      if (newIsFocus) {
        context.emit('focus');
      }

      isFocused.value = newIsFocus;
    };

    onMounted(() => {
      if (isNumberInput.value) {
        checkBoundaryValuesAndCorrectModelValue();
      }
    });

    const onBlur = () => () => {
      setFocus(false);
      if (isNumberInput.value && !isNaN(Number(props.modelValue))) {
        if (props.integer) {
          model.value = Math.round(model.value);
        }

        checkBoundaryValuesAndCorrectModelValue();
      }

      context.emit('blur', model.value !== "" ? (props.integer ? Math.round(formatValueByType(model.value)) : formatValueByType(model.value)) : model.value);
    };

    const checkBoundaryValuesAndCorrectModelValue = (value?: number | string) => {
      const currentValue = formatValueByType(value || props.modelValue);
      if (isNumber(props.max) && currentValue > props.max) {
        //two modelValue loops depending on !!mask
        props.mask ? emitModelValue(props.max) : (model.value = props.max);
      } else if (isNumber(props.min) && currentValue < props.min) {
        props.mask ? emitModelValue(props.min) : (model.value = props.min);
      }
    };

    const isNumber = (value?): boolean => {
      return value !== undefined && value !== null;
    };

    // TODO ogarnąć inputy w taki sposób aby walidowało niewymagane wartość jeśli jest ustawiony typ
    // trzeba sprawdzić jak działają formularze
    const isValid = computed<boolean>(() => {
      if (!props.required || !model.value || isFocused.value) {
        return true;
      }

      switch (props.type) {
        case "email":
          return isEmailValid(model.value);
        case 'password':
          return isPasswordValid(model.value);
        case "phone":
          return isPhoneValid(model.value);
        default:
          return !!model.value;
      }
    });

    const passwordIcon = computed<string>(() => {
      return showPassword.value ? mdiEyeOutline : mdiEyeOffOutline;
    });

    const innerIcon = computed<string>(() => {
      return props.type === 'password' ? passwordIcon.value :
          !isValid.value ? mdiAlertCircleOutline : props.appendInnerIcon;
    });

    const rulesByType = computed(() => {
      const defaultRules = [v => !!v || 'Pole jest wymagane',];
      const emailRules = [
        v => isEmailValid(v) || 'Podaj prawidłowy adres e-mail',
      ];
      const passwordRules = [
        v => isPasswordValid(v) || 'Niepoprawne hasło',
      ];
      const phoneRules = [v => isPhoneValid(v) || 'Niepoprawny numer telefonu',];
      const numberRules = [v => (!!v || (props.min <= 0 && v === 0)) || 'Pole jest wymagane',];
      if (props.required) {
        switch (props.type) {
          case "email":
            return emailRules;
          case 'password':
            return passwordRules;
          case "phone":
            return phoneRules;
          case "number":
            return numberRules;
          default:
            return defaultRules;
        }
      } else {
        return [];
      }
    });

    const appendIconAction = (event) => {
      if (isNumberInput.value && props.step) {
        const newValue = model.value === undefined || model.value === null ?
          props.initialValue || props.step :
          Number(props.modelValue) + props.step;
        model.value = newValue;
        nextTick(() => {
          checkBoundaryValuesAndCorrectModelValue();
        });
      } else if (props.type === 'password') {
        showPassword.value = !showPassword.value;
      }

      if (event?.stopPropagation) {
        event.stopPropagation();
      }
    };

    const prependIconAction = (event) => {
      if (isNumberInput.value && props.step) {
        const value = model.value === undefined || model.value === null ? props.initialValue || props.step : props.modelValue;
        const newValue = Number(value) - props.step;
        model.value = newValue;
        nextTick(() => {
          checkBoundaryValuesAndCorrectModelValue();
        });
      }

      if (event?.stopPropagation) {
        event.stopPropagation();
      }
    };

    const htmlInputType = computed(() => {
      const lookPassword = props.type === 'password' && showPassword.value;
      const hidePassword = props.type === 'password' && !showPassword.value;
      if (props.mask) return 'text';
      else if (props.type === 'phone') return 'tel';
      else if (lookPassword) return 'text';
      else if (hidePassword) return 'password';
      else return props.type;
    });

    return () => (
        <LField error={!isValid.value} class={[props.class,]} isFocused={isFocused.value} required={props.required}
                tooltip={props.tooltip} label={props.label} id={props.id}>
          <VTextField
              v-maska={props.mask}
              v-model={model.value}
              onMaska={props.mask ? setUnmaskedModelFromEvent : ''}
              id={props.id}
              onBlur={onBlur()}
              onFocus={() => setFocus(true)}
              min={props.min}
              max={props.max}
              onInput={(event) => context.emit('input', event)}
              onClick:prependInner={prependIconAction}
              onClick:appendInner={appendIconAction}
              class={[
                'l-input',
                props.class,
                props.textCenter && 'input-text-center',
                props.prefix && 'input-with-prefix',
                props.suffix && 'input-with-suffix',
                !isValid.value && 'error-icon',
              ]}
              density={props.density}
              append-icon={props.appendIcon}
              aria-label={props.label}
              prepend-icon={props.prependIcon}
              placeholder={props.placeholder}
              prefix={props.prefix}
              suffix={props.suffix}
              type={htmlInputType.value}
              autofocus={props.autofocus}
              bgColor={props.theme === 'dark' ? 'backgroundSecondary' : props.bgColor}
              clearable={props.clearable}
              clear-icon={props.clearIcon}
              color={props.color}
              theme={props.theme}
              disabled={props.disabled}
              error={props.error}
              error-messages={props.errorMessages}
              variant={props.variant}
              hide-details={props.hideDetails}
              hint={props.hint}
              loading={props.loading}
              persistent-hint={props.persistentHint}
              persistent-placeholder={props.persistentPlaceholder}
              append-inner-icon={innerIcon.value}
              prepend-inner-icon={props.prependInnerIcon}
              readonly={props.readonly}
              rules={[...rulesByType.value, ...props.rules,]}
              single-line={props.singleLine}
              success-messages={props.successMessages}
              style={`--inputWidth: ${inputWidth.value}`}
              pattern={isNumberInput.value ? '[0-9]*' : ''}
              inputmode={isNumberInput.value ? 'numeric' : 'text'}
              autocomplete={props.isSmsAutocomplete ? 'one-time-code' : ''}
              data-cy={props.dataCy}
          >
            {{
              'append-inner': () => context.slots['append-inner'] ? context.slots['append-inner']() : undefined
            }}
          </VTextField>
          {(props.maxLabel || props.minLabel) &&
              <div class="flex justify-between mt-2">
                {props.minLabel && <span>{props.minLabel}</span>}
                {props.maxLabel && <span>{props.maxLabel}</span>}
              </div>
          }
        </LField>
    );
  },
});
</script>
<style scoped lang="scss">
.input-text-center {
  :deep(.v-field__input) {
    flex-basis: calc(var(--inputWidth) + 1px) !important;
    flex-grow: unset;
    padding: 0;

    input {
      width:  calc(var(--inputWidth) + 1px);
    }
  }
  :deep(.v-text-field__suffix) {
    margin-left: 0.5ch;
    padding-right: 0;
    padding-inline-end: 0;
  }
}

:deep(input::-webkit-outer-spin-button),
:deep(input::-webkit-inner-spin-button) {
  -webkit-appearance: none;
  margin: 0;
}

:deep(input[type=number]) {
  -moz-appearance: textfield;
}

.valid-password {
  :deep(.v-icon) {
    color: rgb(var(--v-theme-success));
  }
}

.error-icon {
  :deep(.v-icon) {
    color: rgb(var(--v-theme-error));
  }
}
</style>

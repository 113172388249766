import {ComponentObjectPropsOptions} from "@vue";
type TextPropsType = {
    suffix: string,
    prefix: string,
    textSuffix: string,
    textPrefix: string
}
export const TextProps: ComponentObjectPropsOptions<TextPropsType> = {
  suffix: {
    default: '',
    type: String,
  },
  prefix: {
    default: '',
    type: String,
  },
  textSuffix: {
    default: '',
    type: String,
  },
  textPrefix: {
    default: '',
    type: String,
  },
};

export default function useValidation() {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRegex = /(?:(?:(?:\+|00)?48)|(?:\(\+?48\)))?(?:1[2-8]|2[2-69]|3[2-49]|4[1-8]|5[0-9]|6[0-35-9]|[7-8][1-9]|9[145])\d{7}$/;
    const nameRegex = /^[A-Za-z0-9ąęłńóśźżĄĘŁŃÓŚŹŻ]{3,}$/;
    const internationalPhoneRegex = /[0-9]{0,14}$/;

    const isDirty = ref<boolean>(false);

    const rulesCallback = (warning: string, value: boolean) => {
        isDirty.value = !value;
        return warning;
    };

    const isValid = (value): boolean => !!value;
    const isEmailValid = (value): boolean => typeof value === 'string' && emailRegex.test(value);
    const isPasswordValid = (value): boolean => typeof value === 'string' && passwordRegex.test(value);
    const isPhoneValid = (value): boolean => {
        const formattedValue = value && value.replace(/\s/g, '');
        return  typeof formattedValue === 'string' && formattedValue.length === 12 || 9 && phoneRegex.test(formattedValue);
    };
    const isInternationalPhoneValid = (value: string) => {
        const formattedValue = value && value.replace(/\s/g, '');
        return  typeof formattedValue === 'string' && internationalPhoneRegex.test(formattedValue);
    };
    const isTaxIdentificationNumberValid = (nip: string ): boolean => {
        const weight: number[] = [6, 5, 7, 2, 3, 4, 5, 6, 7,];
        let sum: number = 0;
        const controlNumber: number = parseInt(nip.substring(9, 10));
        const weightCount: number = weight.length;
        for (let i = 0; i < weightCount; i++) {
          sum += (parseInt(nip.substr(i, 1)) * weight[i]);
        }

        return sum % 11 === controlNumber;
    };

    const isNameValid = (value: string): boolean => {
        // This names values, are consider to be empty
        const forbiddenNames = ["empty", "anonymous_client", "test"];

        if (forbiddenNames.includes(value)) {
            return false;
        }

        return nameRegex.test(value);
    }

    return {
        passwordRegex,
        emailRegex,
        phoneRegex,
        isDirty,
        isValid,
        rulesCallback,
        isEmailValid,
        isPasswordValid,
        isPhoneValid,
        isInternationalPhoneValid,
        isTaxIdentificationNumberValid,
        isNameValid,
    };
}

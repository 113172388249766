import {ComponentObjectPropsOptions, PropType} from "@vue";
type HintProps = {
    errorMessages: string[],
    messages: string[],
    success: boolean,
    successMessages: string[],
    hint: string,
    persistentHint: boolean
}
export const HintProps: ComponentObjectPropsOptions<HintProps> = {
  errorMessages: {
    default: [],
    type: Array as PropType<string[]>,
  },
  messages: {
    default: [],
    type: Array as PropType<string[]>,
  },
  success: {
    default: false,
    type: Boolean,
  },
  successMessages: {
    default: [],
    type: Array as PropType<string[]>,
  },
  hint: {
    default: undefined,
    type: String,
  },
  persistentHint: {
    default: false,
    type: Boolean,
  },
};

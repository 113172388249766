import {ComponentObjectPropsOptions} from "@vue";

type NumberInputProps =  {
    max: number | string,
    maxLabel: string,
    min: number | string,
    minLabel: string,
    step: number,
    initialValue: number,
    integer?: boolean,
}
export const NumberInputProps: ComponentObjectPropsOptions<NumberInputProps> = {
    max: {
        type: Number || String,
    },
    maxLabel: {
        type: String,
    },
    min: {
        type: Number || String,
    },
    minLabel: {
        type: String,
    },
    step: {
        default: 1,
        type: Number,
    },
    initialValue: {
        type: Number,
    },
    integer: {
        type: Boolean,
    },
};
